<div id="login-error" *ngIf="appConstants.isShowingMessage">
  <h4 *ngIf="appConstants.errorMessageHeader && appConstants.errorMessageHeader != ''">{{ appConstants.errorMessageHeader }}</h4>
  <p *ngIf="appConstants.errorMessageText && appConstants.errorMessageText != ''">{{ appConstants.errorMessageText }}</p>

  <div *ngIf="appConstants.errorMessageText == 'Email is not confirmed'">
    <p class="verify-email-msg" translate>Page.Messages.RESEND_VERIFICATION_QUESTION</p>
    <p class="details"><a href="/account/resend-verification" routerLink="/account/resend-verification" [translate]>Page.Buttons.RESEND_VERIFICATION</a></p>
  </div>
  
  <button type="button" name="button" (click)="appConstants.isShowingMessage = false;">Close</button>
</div>