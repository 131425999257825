<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer
      class="sidenav"
      fixedInViewport="false"
      (click)="drawer.toggle()"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'push' : 'side'"
      position="end"
      >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item *ngIf="isLoggedIn" routerLink="/dashboard" routerLinkActive="alink" title="{{ 'Page.ToolTips.DASHBOARD' | translate }}"><p [translate]>Page.Labels.DASHBOARD</p></a>
      <a mat-list-item *ngIf="isLoggedIn" routerLink="/map" routerLinkActive="alink" title="{{ 'Page.ToolTips.MAP' | translate }}"><p [translate]>Page.Labels.MAP</p></a>
      <a mat-list-item *ngIf="isLoggedIn" routerLink="/units" routerLinkActive="alink" title="{{ 'Page.ToolTips.UNITS' | translate }}"><p [translate]>Page.Labels.UNITS</p></a>
      <!-- <a mat-list-item *ngIf="isLoggedIn" routerLink="/vehicles" routerLinkActive="alink" title="{{ 'Page.ToolTips.VEHICLES' | translate }}"><p [translate]>Page.Labels.VEHICLES</p></a> -->
      <a mat-list-item *ngIf="isLoggedIn" routerLink="/reports" routerLinkActive="alink" title="{{ 'Page.ToolTips.REPORTS' | translate }}"><p [translate]>Page.Labels.REPORTS</p></a>
      <a mat-list-item *ngIf="isLoggedIn" routerLink="account/profile" routerLinkActive="alink" title="{{ 'Page.ToolTips.PROFILE' | translate }}"><p [translate]>Page.Labels.PROFILE</p></a>
      <!-- <a mat-list-item routerLink="/about" routerLinkActive="alink"><p [translate]>Page.Buttons.ABOUT</p></a> -->
      <a mat-list-item *ngIf="!isLoggedIn" routerLink="account/login" routerLinkActive="alink" title="{{ 'Page.ToolTips.LOGIN' | translate }}"><p [translate]>Page.Buttons.LOGIN</p></a>
      <!-- <a mat-list-item *ngIf="!isLoggedIn" routerLink="account/register" routerLinkActive="alink" title="{{ 'Page.ToolTips.REGISTER' | translate }}"><p [translate]>Page.Buttons.REGISTER</p></a> -->
      <a mat-list-item *ngIf="isLoggedIn" routerLink="account/login" routerLinkActive="alink" title="{{ 'Page.ToolTips.LOGOUT' | translate }}" (click)="logout()"><p [translate]>Page.Buttons.LOGOUT</p></a>
      <!-- <div class="lang"> -->
        <p mat-list-item (click)="switchLanguage('en')" [ngClass]="{'active': language === 'English'}" ><img src="/assets/flags/uk.png"> English</p>
        <p mat-list-item (click)="switchLanguage('sv')" [ngClass]="{'active': language === 'Svenska'}" ><img src="/assets/flags/swe.png"> Svenska</p>
      <!-- </div> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div *ngIf="!companyUpdateClosed" class="company-update" [class.fade-out]="cookieMessagefadeOut">
      <p>{{ 'Page.Messages.COOKIE_POLICY' | translate }}</p>
      <a href="https://taggr.notion.site/cookie-policy">{{ 'Page.Messages.COOKIE_POLICY_LINK' | translate }}</a>
      <img src="/assets/icons/svg/close.svg" (click)="closeCompanyUpdate()" />
    </div>
    <mat-toolbar color="primary" class="app-toolbar">

      <a *ngIf="!isBoomrCustomer" routerLink="/" class="logo"><img src="{{appConstants.colorThemes[appConstants.currentThemeId].Constants.Menu.LogotypeImageUrl}}"></a>
      
      <a *ngIf="isBoomrCustomer"  class="logo"><img src="{{appConstants.colorThemes[appConstants.currentThemeId].Constants.Menu.LogotypeImageUrl}}"></a>

      <!-- App Version Number-->
      <p *ngIf="companyId === 1" class="app-version-number">{{appVersion}}</p>
      <span class="spacer"></span>

      <div class="topmenu">
        <ul *ngIf="!(isHandset$ | async)">
          
          <li><a *ngIf="isLoggedIn && !isBoomrCustomer" class="link-btn" routerLink="/dashboard" routerLinkActive="alink" title="{{ 'Page.ToolTips.DASHBOARD' | translate }}" [translate]>Page.Labels.DASHBOARD</a></li>
          <li><a *ngIf="isLoggedIn && !isBoomrCustomer" class="link-btn" routerLink="/map" routerLinkActive="alink" title="{{ 'Page.ToolTips.MAP' | translate }}" [translate]>Page.Labels.MAP</a></li>
          <li><a *ngIf="isLoggedIn && !isBoomrCustomer" class="link-btn" routerLink="/units" routerLinkActive="alink" title="{{ 'Page.ToolTips.UNITS' | translate }}" [translate]>Page.Labels.UNITS</a></li>
          <!-- <li><a *ngIf="isLoggedIn" routerLink="/vehicles" routerLinkActive="alink" title="{{ 'Page.ToolTips.VEHICLES' | translate }}" [translate]>Page.Labels.VEHICLES</a></li> -->
          <li><a *ngIf="isLoggedIn && !isBoomrCustomer" class="link-btn" routerLink="/reports" routerLinkActive="alink" title="{{ 'Page.ToolTips.REPORTS' | translate }}" [translate]>Page.Labels.REPORTS</a></li>
          <li><a *ngIf="isLoggedIn && !isBoomrCustomer" class="link-btn" routerLink="account/profile" routerLinkActive="alink" title="{{ 'Page.ToolTips.PROFILE' | translate }}" [translate]>Page.Labels.PROFILE</a></li>

          <!-- <li><a routerLink="/about" routerLinkActive="alink" [translate]>Page.Buttons.ABOUT</a></li> -->
          <!-- <li><a *ngIf="!isLoggedIn && !isBoomrCustomer" class="link-btn" routerLink="account/login" routerLinkActive="alink" [translate]>Page.Buttons.LOGIN</a></li> -->
          <!-- <li><a *ngIf="!isLoggedIn" class="link-btn" routerLink="account/register" routerLinkActive="alink" [translate]>Page.Buttons.REGISTER</a></li> -->
          <li><a *ngIf="isLoggedIn && !isBoomrCustomer" class="btn-usermanual" routerLink="/usermanual" routerLinkActive="alink" [translate]>Page.Labels.USERMANUAL</a></li>
          <li>
            <!-- <button mat-button [matMenuTriggerFor]="lang" title="Change language"><img src="assets/icons/svg/language.svg" class="language" alt="lang"></button> -->

            <div class="nav-flag" (click)="switchLanguage('sv')" *ngIf="language == 'Undefined'" title="{{'Page.ToolTips.CHANGE_LANGUAGE' | translate}}"><img src="/assets/flags/undefined.png" class="flag" alt="lang"></div>
            <div class="nav-flag" (click)="switchLanguage('sv')" *ngIf="language == null" title="{{'Page.ToolTips.CHANGE_LANGUAGE' | translate}}"><img src="/assets/flags/uk.png" class="flag" alt="lang"></div>
            <div class="nav-flag" (click)="switchLanguage('sv')" *ngIf="language == 'English'" title="{{'Page.ToolTips.CHANGE_LANGUAGE' | translate}}"><img src="/assets/flags/uk.png" class="flag" alt="lang"></div>
            <div class="nav-flag" (click)="switchLanguage('en')" *ngIf="language == 'Svenska'" title="{{'Page.ToolTips.CHANGE_LANGUAGE' | translate}}"><img src="/assets/flags/swe.png" class="flag" alt="lang"></div>

            <!-- <button mat-button *ngIf="language == 'English'"[matMenuTriggerFor]="lang" title="{{'Page.ToolTips.CHANGE_LANGUAGE' | translate}}"><img src="/assets/flags/uk.png" class="flag" alt="lang"></button>
            <button mat-button *ngIf="language == 'Svenska'" [matMenuTriggerFor]="lang" title="{{'Page.ToolTips.CHANGE_LANGUAGE' | translate}}"><img src="/assets/flags/swe.png" class="flag" alt="lang"></button>
            <mat-menu #lang="matMenu">
              <button (click)="switchLanguage('en')" [class.active]="language == 'English'" ><img src="/assets/flags/uk.png"> English</button>
              <button (click)="switchLanguage('sv')" [class.active]="language == 'Svenska'" ><img src="/assets/flags/swe.png"> Svenska</button>
            </mat-menu> -->
          </li>
          <li><a *ngIf="isLoggedIn && !isBoomrCustomer" routerLink="account/login" routerLinkActive="alink" (click)="logout()" title="{{'Page.Labels.LOGOUT' | translate}}">
            <div class="logout-link">
              <img src="assets/icons/svg/logout.svg" />
              <span translate>Page.Buttons.LOGOUT</span>
            </div>
          </a></li>
        </ul>

      </div>

      <!-- <div *ngIf="isLoggedIn && accountDetails?.Name" class="logged-in-user"><span class="logged-in-label" translate>Page.Labels.LOGGED_IN_AS</span> <span class="logged-in-name">{{ accountDetails.Name }}</span> <img src="assets/icons/svg/logout.svg" (click)="logout()" ngbTooltip="{{'Page.Labels.LOGOUT' | translate}}" /></div> -->


      <!--
      <div *ngIf="!(isHandset$ | async)">
        <a href="#">Link 1</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a>
      </div> -->

      <button
        class="hamburg"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon class="icon" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    </mat-toolbar>
    <!-- Add Content Here -->
    <!-- <p *ngIf="isHandset$ | async"></p> -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>


<!-- https://www.youtube.com/watch?v=Q6qhzG7mObU -->
