import { Injectable } from '@angular/core'; // INJECTOR FROM CS24 (not used?)
import { Constants } from '../app.config';
import { TranslateService } from '@ngx-translate/core';
import { Observable, EMPTY, of } from 'rxjs';
import { Subject } from 'rxjs';

import { ApiService } from './api.service';

import { retry, catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import 'rxjs-compat'
import { throwError } from 'rxjs/internal/observable/throwError';
import 'rxjs/add/operator/catch';
import { Account, IAccount } from '../_models/account.model';
import { JwtService } from './jwt.service';
import { LocalstoreService } from '../_shared/localstore.service';
import { HttpParams } from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class AccountService extends Account {
  private accountObservable: Observable<IAccount> = null;
  // @Output() fireIsLoggedIn: EventEmitter<any> = new EventEmitter<any>();
  public language = new Subject<string>();

  constructor(
    
    public translate: TranslateService,
    private jwtService: JwtService,
    private localStore: LocalstoreService,
		//private http: HttpClient,
    private appConstants: Constants,
    private api: ApiService
    //private authService: AuthService
  ) {
    super();
		// console.log("TLOG (login-service): Running constructor");

    // let params = new HttpParams();
    // params = params.append('external', 'COMMON');
    //  = { params: params };

    this.language.subscribe(longLanguage =>{
      var language;
      switch(longLanguage){
        case "Svenska":
          language = "sv";
          break;
        default:
          language = "en";
          break;
      }
      this.translate.use(language);
    });
  }


  // - - - - - - - - API: Account - - - - - - - -

  // GET ​/api​/v1​/Account  (Get account data for logged in account)
  getAccount(): Observable<IAccount> {
    if(this.accountObservable === null) {    
      this.accountObservable = this.api.get('account')
      .pipe(
        map(account => { 
          this.localStore.store('accountDetails', account);
          return account;
        }) 
      )
      .share()
  }
    return this.accountObservable;
}


 // (Duplicate -> Remove this)
getAccountDetails(): Observable<IAccount> {
  console.warn("Stop calling getAccountDetails, call getAccount instead..");
  if(this.accountObservable === null) {    
      this.accountObservable = this.api.get('account')
      .pipe(
        map(account => { 
          this.localStore.store('accountDetails', account);
          return account;
        }) 
      )
      .share()
  }
  return this.accountObservable;
}



  /**
   * Method to register.
   * @param companyId
   */
  getCompanyDetails(): Observable<any> {
    // console.log("TLOG (login-service): Running getCompanyDetails()");
    return this.api
      // .get('Account/' + companyId)
      .get('customer/')
      .pipe(map((details: any) => {
        this.localStore.store('companyDetails', details);
        return details;
      }));
  }

  // PUT /api/account (Update account information for logged in account)
  editProfileData(companyId, data): Observable<any> {
    return this.api.put(
      'account/',
      data    );
  }

  // DOUBLE ?
  setLanguage(language): Observable<any> {
    var userInfo = this.localStore.get('accountDetails');
    let data = {
      Language: language,
      Name: userInfo.Name,
	    SpeedUnit: userInfo.SpeedUnit,
      TimezoneId: userInfo.TimeZone.TimeZoneId
    };
    userInfo.Language = data.Language;
		this.localStore.store('accountDetails', userInfo);
    this.language.next(language);
    return this.api.put(
      'account/',
      data
    );
  }

  // POST /api/account/confirm-email/{confirmationId}?acceptInformationEmails (Update account Confirmation status)
  confirmEmail(confirmationId: string, acceptInformationEmails: boolean): Observable<any> {
    const params = new HttpParams().set('acceptInformationEmails', acceptInformationEmails.toString());
    return this.api.post(`Account/confirm-email/${confirmationId}`, {}, { params });
  }
  
  // POST /api/account/change-password (Change password for logged in account)
  changePassword(data): Observable<any> {
    // console.log("TLOG: Running function: changePassword");
    return this.api
      .post('Account/change-password',
      data,
      );
  }

  // POST /api/account/generate-password-recovery (Recover password for account)
  initiateResendConfirmation(data): Observable<any> {
    return this.api
      .post('Account/generate-password-recovery',
      data,
      );
      // .post('Account/resend-confirmation', data); OLD CODE
  }

  // DOUBLE ?
  initiateResetPasswprdRequest(data): Observable<any> {
    return this.api
      .post('Account/generate-password-recovery', data);
  }



  // POST /api/account/reset-password (Change an account's password)
  resetPassword(data): Observable<any> {
    // console.log("TLOG: Running function: resetPassword");
    return this.api
      .post('Account/reset-password', data);
  }


  // POST /api/account/generate-change-email-ticket
  //     Generates a ticket to change email for logged in Account.


  // POST /api/account/change-email
  //     Change account email with a verification code received from a Change Email Ticket


  // POST /api/account/resend-confirmation-email
  //     Send a Confirmation code to an email registered with an Account. Account Email must be unconfirmed.
  resendConfirmationMail(data): Observable<any> {
    return this.api.post(
      'Account/resend-confirmation-email',
      data,
    );
  }



  // - - - - - - - - API: Contact - - - - - - - -

  // GET /api/contacts (Get contacts for specified account)
  getAllContacts(): Observable<any> {
    return this.api.get(
      'contacts',
      
    );
  }

  // POST /api/contacts (Create a new contact)
  createNewContact(customerId, data): Observable<any> {
    return this.api.post(
      'contacts',
      data,
      
    );
  }

  // DELETE /api/contacts/{contactId} (Delete a contact)
  deleteMyContact(contactId): Observable<any> {
    return this.api.delete(
      'contacts/' + contactId,
      
    );
  }

  // GET /api/contacts/{contactId} (Get specified contact)

  // PUT /api/contacts/{contactId} (Update a contact)
  editMyContact(contactId, data): Observable<any> {
    return this.api.put(
      'contacts/' + contactId,
      data,
      
    );
  }

  // PUT /api/contacts/{contactId}/alerts (Update alert types of specified Contact)

  // GET /api/contacts/alerts (Get an array of all Alerts used for input)





  // - - - - - - - - API: Customer - - - - - - - -

  // GET /api/customer (Get logged in Account's Customer object)
  getCustomer(): Observable<any> {
    return this.api.get(
      'customer/',
    );
  }

  // POST /api/customer (Register a new account)
  /**
   * Method to register.
   * @param userCredentials
   */
  register(userCredentials): Observable<any> {
    // console.log("TLOG (login-service): Running register()");
    return this.api
      .post('Customer', userCredentials)
      .pipe(map((result: any) => {
        const response: any = result;
        return response;
      }));
  }

  // PUT /api/customer (Update logged in Account's Customer information)
  editCustomer(customerId, data): Observable<any> {
    return this.api.put(
      'customer',
      data,
      
    );
  }


  // GET /api/customer/account (Get all Accounts for logged in Customer)
  getAccounts(): Observable<any> {
    return this.api.get(
      'customer/account',
    );
  }

  // POST /api/customer/account (Create an additional user for the logged in Customer)
  createNewAccount(customerId, data): Observable<any> {
    return this.api.post(
      'customer/account',
      data,
      
    );
  }

  // DELETE /api/customer/account/{accountId} (Deletes specified Account.)
  deleteMyAccount(accountId): Observable<any> {
    return this.api.delete(
      'customer/account/' + accountId,
      
    );
  }


  // GET /api/customer/account/{accountId} (Get specified Account. Only Customers and accounts with group access can use this action.)


  // PUT /api/customer/account/{accountId} (Update specified account as the Owner)
  editAccount(accId, data): Observable<any> {
    return this.api.put(
      'customer/account/' + accId,
      data,
      
    );
  }


  // PUT /api/customer/account/{accountId}/valid-until (Updates an Account's Valid Until time)


  



  // - - - - - - - - API: Geofence - - - - - - - -

// Should we remove all Geofence calls from AccountService since these are also in GeofenceService?
// The following function is called in DashboardComponent and TrakkerItemComponent but no other Geofence calls are made
// Is it more data intense to import the GeofenceService or have this function in this Service as well?

  // GET /api/geofences (Get geofences for specified account)
  getAllGeofences(): Observable<any> {
    return this.api.get(
      'geofences',
      
    );
  }


  // - - - - - - - - API: Group - - - - - - - -

    // GET /api/groups (Get groups)
    getAllGroups(): Observable<any> {
      return this.api.get(
        'groups',
        
      );
    }

    // POST /api/groups (Create a new group)
    createNewGroup(data): Observable<any> {
      return this.api.post(
        'groups',
        data,
        
      );
    }

    // DELETE /api/groups/{groupId} (Delete a group)
    deleteMyGroup(groupId):Observable<any> {
      return this.api.delete(
        'groups/' + groupId,
        
      );
    }


    // GET /api/groups/{groupId} (Get specified group)
    getGroup(groupId):Observable<any> {
      return this.api.get(
        'groups/' + groupId,
        
      );
    }


    // PUT /api/groups/{groupId}  (Update a group)
    editGroup(groupId, data) {
      return this.api.put(
        'groups/' + groupId,
        data,
        
      );
    }


    // POST /api/groups/{groupId}/account (Add Account to Group. Only Owner and Account Administrator can perform this action)
    addGroupAccount(groupId, data): Observable<any> {
      // console.log("Group ID is: " + groupId + " and data is: " + data);
      return this.api.post(
        'groups/' + groupId + '/account',
        data,
        
      );
    }

    // PUT /api/groups/{groupId}/account (Update Access status for Account in Group)
    updateGroupAccountAccess(groupId, data) {
      return this.api.put(
        'groups/' + groupId + '/account',
        data,
        
      );
    }

    // POST /api/groups/{groupId}/trakker (Add a Trakker to a Group)
    addGroupTrakker(groupId, data): Observable<any> {
      // console.log("Group ID is: " + groupId + " and data is: " + data);
      return this.api.post(
        'groups/' + groupId + '/trakker',
        data,
        
      );
    }

    // POST /api/groups/{groupId}/vehicle (Add a Vehicle to a Group)
    addGroupVehicle(groupId, data): Observable<any> {
      // console.log("Group ID is: " + groupId + " and data is: " + data);
      return this.api.post(
        'groups/' + groupId + '/vehicle',
        data,
        
      );
    }

    // DELETE /api/groups/{groupId}/account/{accountId} (Remove an Account from a Group. Only Account Owners and Account Administrators can perform this action.)
    removeGroupAccount(groupId, accountId): Observable<any> {
      // console.log("Running removeGroupAccount");
      return this.api.delete(
        'groups/' + groupId + '/account/' + accountId,
        
      );
    }

    // DELETE /api/groups/{groupId}/trakker/{trakkerId} (Remove a Trakker from a Group. Only Account Owners and Account Administrators can perform this action.)
    removeGroupTrakker(groupId, trakkerId): Observable<any> {
      // console.log("Running removeGroupTrakker");
      return this.api.delete(
        'groups/' + groupId + '/trakker/' + trakkerId,
        
      );
    }

    // DELETE /api/groups/{groupId}/vehicle/{vehicleId} (Remove a Vehicle from a Group. Only Account Owners and Account Administrators can perform this action.)
    removeGroupVehicle(groupId, vehicleId): Observable<any> {
      // console.log("Running removeGroupTrakker");
      return this.api.delete(
        'groups/' + groupId + '/vehicle/' + vehicleId,
        
      );
    }

    // PUT /api/groups/{groupId}/favorite (Updates logged in account's favorite status in specified Group)
    editGroupFavorite(groupId: any, data: { NewFavoriteStatus: boolean; }): Observable<any> {
      return this.api.put(
        'groups/' + groupId + '/favorite/',
        data,
        
      );
    }






  // - - - - - - - - API: Token - - - - - - - -

    // GET /api/timezones (Get all timezones)
    getAllTimezones(): Observable<any> {
      return this.api.get(
        'timezones',
        
      )
    }





  // - - - - - - - - API: Token - - - - - - - -

    // GET /api/token/{verification} (Retrieves JWT using a verification code. Can only be used once.)
    // same as the one below?
    /**
     * Method to login for external users.
     * @param userCredentials
     */
    getTokenForExternalAccount(key): Observable<any> {
      // console.log("TLOG (login-service): Running getTokenForExternalAccount()");
      return this.api
        .get('token/' + key)
    }

    // GET /api/token/{verification}
    getTokenByGuid(guid) {
      return this.api.get(
        'token/' + guid
      );
    }


    // POST /api/token (Generates a JWT for customer. Valid for 24 hours.)
    /**
     * Method to login.
     * @param userCredentials
     */
    getToken(userCredentials): Observable<any> {
      return this.api.post('token', userCredentials)
        .pipe(map(data => {
          if(data){
            this.jwtService.saveToken(data)
          }
          return data;
        }))
    }

    // GET /api/token/refresh (Refresh Account token. Does not refresh Expiration date.)
    refreshToken(): Observable<any> {
      return this.api
        .get('token/refresh')
        .pipe(map((result: any) => {
          const response: any = result;
          if(response){
            this.jwtService.saveToken(response)
          }
          return response;
        }), 
        catchError( e => {
          return EMPTY;
        }));
    }



  /**
   * Method to logout.
   */
  logout(): Observable<any> {
    // console.log("TLOG (login-service): Running logout()");
    return this.api
      .get('logout')
      .pipe(map((res: any) => {
        const response = res.json();
        return response;
      }),
      catchError((err, caught) => {
        return Observable.of(false);
      }));
  }


  // errorHandler(error: HttpErrorResponse) { }

/*   handleError(error) {
   let errorMessage = '';
   if (error.error instanceof ErrorEvent) {
     // client-side error
     errorMessage = `Error: ${error.error.message}`;
   } else {
     // server-side error
     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
   }
   window.alert(errorMessage);
   return throwError(errorMessage);
 } */


//  PERSONAL GROUP CALLS



    // GET​/api​/v1​/customer​/account​/{accountId}​/trakkers (Get trakkers in personal group for account)
    getPersonalTrakkers(accountId):Observable<any> {
      return this.api.get(
        'customer/account/' + accountId + '/trakkers'
      )
      .pipe(
        retry(1)
      );
    }


    // POST​/api​/v1​/customer​/account​/{accountId}​/trakkers​/{trakkerId}   (Add trakker to account's personal group)
    addPersonalTrakker(accountId, trakkerId): Observable<any> {
      return this.api.post(
        'customer/account/' + accountId + '/trakkers/' + trakkerId
      );
    }


    // DELETE​/api​/v1​/customer​/account​/{accountId}​/trakkers​/{trakkerId}
    deletePersonalTrakker(accountId, trakkerId):Observable<any> {
      return this.api.delete(
        'customer/account/' + accountId + '/trakkers/' + trakkerId
      );
    }






}
